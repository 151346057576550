import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Constrain from "../../../../../components/constrain"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FloatContainer from "../../../../../components/float-container"
import Heading from "../../../../../components/heading"
import Image from "../../../../../components/image"
import QuoteBox from "../../../../../components/quote-box"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const data = useStaticQuery(graphql`
    query ArgumenteQuery {
      hyperion: file(
        relativePath: {
          eq: "dauerausstellung/01-die-linien-des-lebens/hyperion-ausgabe-zimmer.jpg"
        }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/work-and-travel" />
      }
    >
      <Seo title="Argumente sammeln" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlins Beweggründe
        </Heading>
        <Paragraph>
          Für Hölderlin waren die Hauslehrer-Stellen in erster Linie ein Weg, um
          sich der Theologen-Laufbahn zu entziehen, die für ihn vorgesehen war.
          Sie brachten ihm gerade so viel Geld ein, um davon leben zu können.
          Unterkunft und Verpflegung waren ohnehin inbegriffen. Die freie Zeit,
          die ihm daneben blieb, nutzte er für das Schreiben. Darüber hinaus
          boten ihm die wechselnden Hauslehrer-Stellen aber auch eine
          Möglichkeit, in die Welt hinauszuziehen, neue Landschaften, Orte und
          Menschen kennenzulernen. Ohne die Anstellung in Bordeaux hätte er wohl
          nie die Gelegenheit gehabt, das Meer zu sehen. Wie aus dem Brief an
          seinen Freund Böhlendorf hervorgeht, verband Hölderlin mit dem
          Aufbruch nach Bordeaux die Chance auf einen Neuanfang, der ihm in der
          Heimat nicht mehr möglich schien.
        </Paragraph>
        <Box>
          <FloatContainer align="right" width="40%">
            <Image
              image={data.hyperion.childImageSharp.gatsbyImageData}
              caption="›Hyperion‹-Ausgabe aus dem Besitz von Ernst Zimmers"
              alt="Vitrine mit einer alten Ausgabe von Hölderlins ›Hyperion‹ aus dem Besitz von Ernst Zimmer"
            />
          </FloatContainer>
          <Paragraph>
            In seinem Roman ›Hyperion‹ hat Hölderlin den Lauf eines
            Menschenlebens als »exzentrische Bahn« beschrieben: Um sich zu
            entwickeln, muss sich der Einzelne aus seinem Zentrum heraus
            bewegen. Indem er Fremdes kennenlernt, kommt er zu sich selbst und
            findet seine Position in der Welt. Im Reisen öffnet sich der Mensch
            für neue Eindrücke, andere Kulturen und Gesellschaften, neue
            Sichtweisen und Lebensarten. Er kann sie sich aneignen oder sich
            davon abgrenzen und entwickelt dadurch eine Vorstellung davon, wie
            er selbst leben möchte. Er wird sich seiner eigenen Kultur und
            seinen eigenen Einstellungen bewusst. Durch die Begegnung mit dem
            Fremden entwickelt er ein Selbst-Bewusstsein.
          </Paragraph>
        </Box>
        <Paragraph>
          So hat auch Hölderlin immer wieder die Sehnsucht nach der Ferne
          gepackt. Doch ebenso sehr zog es ihn von dort in die schwäbische
          Heimat zurück. Hölderlins Zerrissenheit zwischen der verheißungsvollen
          Ferne und der vertrauten Heimat, in der er doch nicht Fuß fassen kann,
          zeichnet sich sowohl in seinen Briefen als auch in seinen Gedichten
          ab. Während Gedichttitel wie ›Der Archipelagus, ›Der gefesselte
          Strom‹, ›Der Ister‹ oder ›Die Wanderung‹ den Blick in die Ferne
          lenken, weisen die Gedichte ›Die Heimath‹, ›Rükkehr in die Heimath‹
          und ›Heimkunft. An die Verwandten‹ in die entgegengesetzte Richtung.
          Ein Zeitgenosse Hölderlins, der Dichter Novalis, hat es einmal so
          formuliert:
        </Paragraph>
        <Constrain align="left">
          <QuoteBox author="Novalis">
            Wohin gehen wir? Immer nach Hause!
          </QuoteBox>
        </Constrain>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
